import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee,faCartShopping ,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import NavDrawer from "./commonComponents/NavDrawer";
import logo from "../assets/images/home/logo.svg"


export default function Navbar({cart}) {
  const classes = styles();

  return (
    <Box >
      <AppBar position="static" className={classes.navBarContainer}>
        <div className="container">
          <div className="navbarContent">
            <div className="homepageLogo">
              <Link to="/" className="logoBlock">
              
                <img
                  alt="logo"
                  data-src="/wp-content/themes/atmantanresorts/assets/images/atmantan.svg"
                  className="logoIn showForMob lazyload"
                  src={logo}
                  // src="https://atmantan.com/wp-content/themes/atmantanresorts/assets/images/atmantan.svg"
                />
               
              </Link>
            </div>

            <div className="navbarItemBlock">
              <ul className="navbarList">
                {/* <li className="hideForMob dropMenu">
                  <Link to="/" className="navbarList__item">
                    WHAT&apos;S NEW{" "}
                  </Link>
                </li> */}
                  {/* <li className="hideForMob dropMenu">
                  <Link to="/" className="navbarList__item">
                    WHAT&apos;S NEW{" "}
                  </Link>
                </li> */}

                <li className="hideForMob dropMenu">
                  <Link to="/wellness-package" className="navbarList__item">
                    WELLNESS PROGRAMS{" "}
                  </Link>
                </li>
                <li className="hideForMob dropMenu">
                  <Link to="/the-centre" className="navbarList__item">
                    THE CENTER{" "}
                  </Link>
                </li>
                <li className="hideForMob dropMenu">
                  {/* <Link to="/products" className="navbarList__item">
                    PRODUCTS{" "}
                  </Link> */}
                <a href="/products" className="navbarList__item">
                    STORE{" "}
                </a>
                </li>
                <li className="hideForMob dropMenu">
                  <Link to="/wellness-cuisine" className="navbarList__item">
                    WELLNESS CUISINE{" "}
                  </Link>
                </li>
                <li className="hideForMob dropMenu">
                  <Link to="/our-story" className="navbarList__item">
                    OUR STORY{" "}
                  </Link>
                </li>
               {/* {user.email&& <li className="hideForMob dropMenu">
                  <Link to="/book" className="navbarList__item">
                  BOOKING
                  </Link>
                </li>}
               {user.email && <li className="hideForMob dropMenu">
                  <Link to="/myBooking" className="navbarList__item">
                  MY BOOKING
                  </Link>
                </li>} */}
          
                <li className="hideForMob">
                  <a href="tel:+912066766666">
                    <span className="btn btn--outlined">
                      <label className="iconIn iconIn--call">  <FontAwesomeIcon  className="icon" icon={faPhone} /></label>
                    </span>
                  </a>
                </li>
                
                <li className="hideForMob">
  <a href="mailto:Contact@mavavida.com">
    <span className="btn btn--outlined">
      <label className="iconIn iconIn--call">
        <FontAwesomeIcon className="icon" icon={faEnvelope} title="Send an email" />
      </label>
    </span>
  </a>
</li>



                {/* <li className="hideForMob">
  <a href="mailto:Contact@mavavida.com">
    <span className="btn btn--outlined">
      <label className="iconIn iconIn--call">
        <FontAwesomeIcon className="icon" icon={faEnvelope} title="Send an email" />
      </label>
    </span>
  </a>
</li> */}



                <li className="hideForMob">
                  <Link to="/contact-us" className="navbarList__item">
                    <span className="btn btn--outlined">Contact Us</span>
                  </Link>
                </li>
                    {/* { user.email?<li onClick={logOut} className="hideForMob dropMenu">
                  <button  className="logout-btn">
                   Logout
                  </button>
                </li>: <li className="hideForMob dropMenu">
                  <Link to="/login" className="navbarList__item">
                   LOGIN
                  </Link>
                </li>} */}
              </ul>
            </div>
            {/* <div className="shopping-cart">
            <FontAwesomeIcon icon={faCartShopping} color='white'/>
            <p className="shopping-number">0</p>
            </div> */}
            <NavDrawer/>
            {/* <Toolbar className="hamburger">
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar> */}
          </div>
        </div>
      </AppBar>
    </Box>
  );
}

const styles = makeStyles((theme) => ({
  navBarContainer: {
    background: "#455010 !important",
    position: "sticky",
    zIndex:1000
  },
}));
