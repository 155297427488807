import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import './NewReview.css'
import averageRating from "../../assets//images/new_review/average_rating.png"
import handsShakeLogo from "../../assets//images/new_review/handshake_logo.png"
import thumbsUpLogo from "../../assets//images/new_review/thumbs_up_logo.png"
import newReviewData from "./NewReviewData"
import Carousel from "react-bootstrap/Carousel"

function NewReviewCards(props){
    //dummy commit
    return (
            <div className="new-review-individual-card">
            <div className="new-review-heading">
                <h3>{props.name}</h3> <span>{props.date}</span>
            </div>    

            <div className="new-review-ratings-container">
            <div className="column">
                <div className="item">
                <span className="label">Human team:</span>
                <img src={props.humanTeamStars} alt="Human team"  className="five-star-rating"/>
                </div>

                <div className="item">
                <span className="label">Activities:</span>
                <img src={props.activitiesStars}  alt="Activities" className="five-star-rating"/>
                </div>

                <div className="item">
                <span className="label">Price quality:</span>
                <img src={props.priceQualityStars}  alt="Price quality" className="five-star-rating" />
                </div>
            </div>

            <div className="column">
                <div className="item">
                <span className="label">Around:</span>
                <img src={props.aroundStars}  alt="Around"  className="five-star-rating"/>
                </div>

                <div className="item">
                <span className="label">Accomodation:</span>
                <img src={props.accomodationStars}  alt="Accomodation" className="five-star-rating"/>
                </div>

                <div className="item">
                <span className="label">Feeding:</span>
                <img src={props.feedingStars}  alt="Feeding" className="five-star-rating" />
                </div>
            </div>

            <div className="column third-column">
            <div className="rating-column">
               <div className="rating-score"> <img src={averageRating}  alt="Feeding" /> <span className="rating-number"> {props.averageRating} </span></div>
                <div className="rating-text">Average Rating</div>
            </div>
            </div>

            
            </div>


            {props.comment && <div className="new-review-comment">
                
                <div className="new-review-comment-heading">
                    <img src={thumbsUpLogo}></img>
                    <span>I liked it</span>
                </div>

                <div className="new-review-comment-description">
                  {props.comment}
                </div>

            </div> }
            
           {props.improve && <div className="new-review-to-improve">

                <div className="new-review-to-improve-heading">
                    <img src={handsShakeLogo}></img>
                    <span>To improve</span>
                </div>

                <div className="new-review-to-improve-description">
                    {props.improve}
                </div>

            </div>}
      
            </div>
    )
}


export default function NewReview(){

    const newReviewCards = newReviewData.map((card,index) => {
        return <NewReviewCards
                name={card.name}
                date={card.date}
                humanTeamStars={card.humanTeamStars}
                activitiesStars={card.activitiesStars}
                priceQualityStars={card.priceQualityStars}
                aroundStars={card.aroundStars}
                accomodationStars={card.accomodationStars}
                feedingStars={card.feedingStars}
                averageRating={card.averageRating}
                comment={card.comment}
                improve={card.improve}
                key={card.index}
                />
    })

    return(
        <>

                <div className="new-review-main-container">
                    <Carousel className="new-review-secondary-container">
                        <Carousel.Item>
                            {newReviewCards[0]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[1]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[2]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[3]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[4]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[5]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[6]}
                        </Carousel.Item>
                        <Carousel.Item>
                            {newReviewCards[7]}
                        </Carousel.Item>
                        
                    </Carousel>
                </div>
        </>
    )
}
