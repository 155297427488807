import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function FloatIcon() {
  return (
    <div className='float-icon'>
        
        
        <a href="https://api.whatsapp.com/send?phone=34655179406" target='_blank' rel="noreferrer">
       <WhatsAppIcon sx={{height:'40px',width:'40px',color:'#ffffff'}}/>

       </a>

    </div>
  )
}

export default FloatIcon