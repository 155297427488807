import React, { useEffect } from 'react'

const LegalNote = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="container privacy mt-5 mb-5" >
            To comply with article 10 of Law 34/2002 on Services of the Information Society and Electronic Commerce, we inform users of our data:

            <ul className="mt-4" >
                <li>
                    <span className="bold">Company Name: </span>MavaYoga
                </li>
                <br />
                <li>
                    <b className="bold">Registered Office</b>Calle Fenicios, 27. URB. Aldea del Golf, 26691
                </li>
                <br />
                <li>
                    <b className="bold">NIF:</b>75957049D
                </li>
                <br />
                <li>
                    <b className="bold">Email:</b> mykhattars@gmail.com
                </li>
                <br />
                <li>
                    <b className="bold">Web: </b>Mavavida.com
                </li>
                <br />

            </ul>


            <h4 className="bold">1.- OBJECT</h4>
            <br />

            GLOBAL EMOTIMES, S.L. (From now on also the provider or MavaYoga) as responsible for the website, makes this document available to users, which regulates the use of the website mavavida.com, with which we intend to comply with the obligations provided by the Law 34/2002, on services of the Information Society and Electronic Commerce (LSSICE or LSSI), as well as informing all users of the website regarding the conditions of use of the website.

            <br />
            <br />

            Through the web, MavaYoga provides users with access and use of different services and content made available through the web.
            <br />
            <br />

            Any person who accesses this website assumes the role of user (hereinafter the user), and implies full and unreserved acceptance of each and every one of the provisions included in this Legal Notice, as well as any other legal provisions that may be applicable. app.
            <br />
            <br />
            As users, you should carefully read this Legal Notice on any of the occasions that you enter the web, since it may undergo modifications since the provider reserves the right to modify any type of information that may appear on the web, without There is an obligation to pre-notify or inform users of said obligations, being sufficient the publication on the provider's website.

            <br />
            <br />

            <h4 className="bold">2- CONDITIONS OF ACCESS AND USE OF THE WEB.</h4>
            <br />
            <p className="bold">2.1. Free access and use of the website.</p>

            The provision of services by MavaYoga is free for all users. However, some of the services provided by the provider through the web are subject to the payment of a certain price in the general contracting conditions.

            <br />
            <br />


            <p className="bold">2.2. User registration.</p>

            For inquiries, the prior subscription or registration of Users is not required.
            <br />
            When a reservation or request for it is requested, you must fill in the form enabled for this purpose that involves registering as a user on the platform. This registration will be carried out in the manner expressly indicated in the service section itself.

            <br />
            <br />

            <p className="bold">2.3. Veracity of the information.</p>


            All the information provided by the User must be truthful. For these purposes, the User guarantees the authenticity of the data communicated through the forms for subscribing to the Services. It will be the responsibility of the User to keep all the information provided to MavaYoga permanently updated so that it responds, at all times, to their real situation. In any case, the User will be solely responsible for any false or inaccurate statements made and for the damages caused to the provider or third parties.

            <br />
            <br />

            <p className="bold">2.4. Minors.</p>

            To use the services, minors must always obtain the prior consent of their parents, guardians or legal representatives, who are ultimately responsible for all acts carried out by the minors in their charge. The responsibility for determining the specific content accessed by minors corresponds to those minors, that is why if they access inappropriate content for the Internet, mechanisms must be established on their computers, in particular computer programs, filters and blocks, which allow limiting The contents available and, although they are not infallible, are especially useful to control and restrict the materials that minors can access.
            <br />
            <br />

            <p className="bold">2.5. Obligation to make correct use of the web.</p>

            The User agrees to use the website in accordance with the Law and this Legal Notice, as well as morals and good customs. Indeed, the User will refrain from using the page for illegal or prohibited purposes, harmful to the rights and interests of third parties, or that in any way may damage, disable, overload, deteriorate or prevent the normal use of computer equipment or documents, files and all kinds of content stored on any computer equipment of the provider.
            <br />
            <br />
            In particular, and by way of indication but not exhaustive, the User undertakes not to transmit, disseminate or make available to third parties information, data, content, messages, graphics, drawings, sound or image files, photographs, recordings, software and, in general, any kind of material that:
            <br />
            <br />
            (A) is contrary, disparages or violates fundamental rights and public freedoms recognized constitutionally, in international treaties and other current regulations;
            <br />
            (B) induces, incites or promotes criminal, degrading, defamatory, violent actions or, in general, contrary to the law, morality and public order;
            <br />
            (C) induces, incites or promotes discriminatory actions, attitudes or thoughts based on sex, race, religion, beliefs, age or condition;
            <br />
            (D) is contrary to the right to honor, personal or family privacy or people's own image;
            <br />
            (E) in any way harm the credibility of the provider or third parties; and
            <br />
            (F) constitutes illegal, misleading or unfair advertising.
            <br />
            <br />

            <h4 className="bold">3.- EXCLUSION OF GUARANTEES AND LIABILITY</h4>
            <br />

            The provider is exempt from any type of responsibility derived from the information published on our website, provided that this information has been manipulated or entered by a third party.
            <br />
            <br />
            This website has been reviewed and tested so that it works correctly. In principle, correct operation can be guaranteed 365 days a year, 24 hours a day. However, the provider does not rule out the possibility that there are certain programming errors, or that causes of force majeure, natural disasters, strikes, or similar circumstances may occur that make access to the website impossible.
            <br />
            <br />
            MavaYoga does not grant any guarantee nor is it responsible, in any case, for damages of any kind that may arise from the lack of availability, maintenance and effective operation of the website or its services and contents; of the existence of viruses, malicious or harmful programs in the contents; of the illicit, negligent, fraudulent use or contrary to this Legal Notice and conditions of use; or the lack of legality, quality, reliability, usefulness and availability of the services provided by third parties and made available to users on the website.
            <br />
            <br />
            The provider is not liable under any circumstances for damages that may arise from the illegal or improper use of this website.

            <br />
            <br />

            <h4 className="bold">4.- COOKIES</h4>
            <br />

            The provider's website may use cookies (small information files that the server sends to the computer of the person accessing the page) to carry out certain functions that are considered essential for the proper functioning and visualization of the site. The cookies used are, in any case, of a temporary nature, with the sole purpose of making browsing more efficient, and they disappear when the user session ends. In no case will cookies be used to collect personal information. For more information, see our Cookies Policy.
            <br />
            <br />

            <h4 className="bold">5.- LINKS (LINKS)</h4>
            <br />
            From the website, you may be redirected to sponsored content, advertisements and / or affiliate links and / or third-party websites.
            <br />
            <br />
            Among the links provided, there is the possibility of informing or referring to third party products and / or services. Given that from the web we cannot control the content entered by third parties, MavaYoga does not assume any type of responsibility regarding said content, nor does it guarantee the experience, integrity or quality of its products. In any case, the provider states that it will proceed to the immediate withdrawal of any content that could contravene national or international legislation, morality or public order, proceeding to the immediate withdrawal of the redirection to these websites, informing the authorities competent the content in question
            <br />
            <br />
            MavaYoga will have these links published until they are eliminated or suspended by the provider or by the advertiser, and will not be responsible even if these ads may be indexed by other search engines outside this website.
            <br />
            <br />
            The provider is not responsible for the information and content stored, by way of example but not limited to, in forums, chats, blog generators, comments, social networks or any other means that allows third parties to publish content independently on the website of the lender. However, and in compliance with the provisions of art. 11 and 16 of the LSSICE, the provider makes itself available to all users, authorities and security forces, and actively collaborating in the withdrawal or, when necessary, the blocking of all those contents that may affect or contravene the national or international legislation, third party rights or morality and public order. In the event that the user considers that there may be some content that could be susceptible to this classification, please notify the website administrator immediately.
            <br />
            <br />
            However, MavaYoga informs you that any contractual or extra-contractual relationship that the user formalizes with these third parties, affiliates or advertisers through the link provided by this website, will be valid solely and exclusively between the user and the advertiser and / or third person and MavaYoga will not will have no type of responsibility for the damages or losses that may be caused by the provision of services and / or contractual or extra-contractual relationships made between the user and the advertisers or third parties contacted through this portal since the provider only acts as an intermediary or advertising medium.

            <br />
            <br />

            <h4 className="bold">6.- PROTECTION OF PERSONAL DATA</h4>
            <br />

            The provider is deeply committed to complying with the personal data protection regulations and guarantees full compliance with the obligations set forth, as well as the implementation of the security measures provided in the European Data Protection Regulation and the Spanish protection regulations. of data. For more information, see our Privacy Policy.

            <br />
            <br />

            <h4 className="bold">7.-INTELLECTUAL AND INDUSTRIAL PROPERTY</h4>
            <br />
            The website, including but not limited to: the programming, editing, compilation and other elements necessary for its operation, the designs, logos, texts, photographs and / or graphics are the property of the provider or, if necessary , has the license or express authorization from the authors. All the contents of the website are duly protected by intellectual and industrial property regulations.
            <br />
            <br />
            Regardless of the purpose for which they were intended, the total or partial reproduction, use, distribution and public communication require prior written authorization from the provider. Any use not previously authorized by the provider will be considered a serious breach of the author's intellectual or industrial property rights.
            <br />
            <br />
            The designs, logos, text and / or graphics outside the provider and that may appear on the website, belong to their respective owners, being themselves responsible for any possible controversy that may arise with respect to them. In any case, the provider has the express and prior authorization from them.
            <br />
            <br />
            The provider recognizes in favor of their owners the corresponding industrial and intellectual property rights, not implying the mention or appearance on the website, of the existence of rights or responsibility of the provider over them, nor endorsement, sponsorship or recommendation by part of the same.
            <br />
            <br />
            To make any type of observation regarding possible breaches of intellectual or industrial property rights, as well as any of the contents of the website, you can do so through the email mentioned above.

            <br />
            <br />
            <h4 className="bol"> 8.- APPLICABLE LAW AND JURISDICTION</h4>
            <br />
            For the resolution of controversies or issues related to this website or the activities carried out in it, Spanish legislation will be applied, to which the parties expressly submit, being competent to resolve all derivative or related conflicts with its use the Courts and Tribunals of Girona.
        </div>
    )
}

export default LegalNote