import React from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import f1 from "../assets/footer/f1.png";
import f2 from "../assets/footer/f2.png";
import f3 from "../assets/footer/f3.png";
import f4 from "../assets/footer/f4.png";

import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  const navigate = useNavigate();

  const handleNavigate = (value) => {
    navigate(value);
  };
  return (
    <footer className="pageSection footerSection m-0">
      <div className="container">
        <div className="footerListBoxWrapper">
          <div className="footerListBox">
            <div className="footerListHolder">
              <Link to="/wellness-package" className="no-underline ">
                <p className="hover:text-white footerListHead">WELLNESS PROGRAMS</p>
              </Link>
            </div>

            <div className="footerListHolder">
              <Link to="/the-centre" className="no-underline ">
                <p className="hover:text-white footerListHead">THE CENTRE</p>
              </Link>
            </div>

            <div className="footerListHolder">
              <Link to="/products" className="no-underline ">
                <p className="hover:text-white footerListHead">
                    STORE
                </p>
              </Link>
            </div>
            <div className="footerListHolder">
              <Link to="/wellness-cuisine" className="no-underline ">
                <p className="hover:text-white footerListHead">WELLNESS CUISINE</p>
              </Link>
            </div>

            <div className="footerListHolder">
              <Link to="/our-story" className="no-underline ">
                <p className="hover:text-white footerListHead">OUR STORY</p>
              </Link>
            </div>

            <div className="footerListHolder">
              <Link to="/contact-us" className="no-underline ">
                <p className="hover:text-white footerListHead">CONTACT US</p>
              </Link>
            </div>

          </div>

          <div className="footerListBox w-30">
            <h5 className="footerListHead">Our payment methods</h5>
            <p style={{ color: "white" }}>Safe Payment 100% secure payment</p>
            <div className="payment-images-container">
              <img src={f1} alt="" />
              <img src={f2} alt="" className="ms-3" />
              <img src={f3} alt="" className="ms-3" />
              <img src={f4} alt="" className="ms-3" />
            </div>
          </div>

          <div className="social-links-footer">
            <div className="footerListHolder text-center">
              <p className="footerListHead">Social Links</p>
            </div>
            <div className="footer-icon-container">
              <a className="addressLink" href="https://instagram.com/mava_sustainable_wellness?igshid=ZDdkNTZiNTM=">
              <FontAwesomeIcon
                icon={faInstagram}
                className="footer-icon"
              ></FontAwesomeIcon>
              </a>
              {/* <FontAwesomeIcon
                icon={faWhatsapp}
                className="footer-icon"
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                icon={faFacebook}
                className="footer-icon"
              ></FontAwesomeIcon> */}
            </div>
          </div>
        </div>

        <div className="flexEB copyWriteBlock">
          <p className="copyWriteText">
            © Copyright 2020. All rights reserved
            <br />{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate("/legal-note")}
            >
              Legal Note
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate("/privacy-statement")}
            >
              · Privacy
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate("/Terms-and-conditions")}
            >
              · Terms and Conditions
            </span>{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigate("/cookies-policy")}
            >
              {" "}
              · Cookies
            </span>{" "}
          </p>
          <div className="">
            {/* <p className="addressText">MAVA Wellness</p> */}
            <p className="addressText">
              {/* Vanessa Arenas <br /> Mava Wellness <br />
              Sotogrande y Manilva Beach & Mountain retreats in two of the
              nicest locations in South Spain, Cadiz y Malaga Spain */}
              Mava Khattar ( Maria Vanessa Arenas Khattar)
              <br />
              <a className="addressLink" href="mailto:mykhattars@gmail.com">
              mykhattars@gmail.com <br />
              </a>
              <a className="addressLink" href="tel:+34 655179406">
              +34 685 161 896 <br />
              </a>
              NIF ( fiscal number) 75957049D <br />
              Registered office/ address: Calle Fenicios, 27. URB. Aldea del
              Golf, 26691
            </p>
            <p className="addressText">
              <a
                className="addressLink"
                href="mailto:info@Mavavida.com "
              >
                info@Mavavida.com | mava@mavavida.com | Contact@mavavida.com
              </a>{" "}
              |{" "}
              <br></br> Whatsapp Number: &nbsp;&nbsp;
              <a className="addressLink" href="tel:+34 655179406">
                +34 655179406
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
