import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatIcon from './components/commonComponents/FloatIcon';
import './pages/Global.css';
import TermConditions from './pages/TermConditions/TermConditions';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import CookiesPolicy from './pages/CookiesPolicy/CookiesPolicy';
import LegalNote from  './pages/LegalNote'
import PrivacyStatement from './pages/PrivacyStatement/PrivacyStatement';
import NewReviewPage from './components/HomePage/NewReviewPage';
import NewGallery from './components/HomePage/NewGalllery';


const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Home = lazy(() => import("./pages/HomePage/HomePage"));
const OurStory = lazy(() => import("./pages/OurStory/OurStory"));
const TheCenter = lazy(() => import("./pages/TheCenter/TheCenter"));
const WellnessPackage = lazy(() => import("./pages/WellnessPackage/WellnessPackage"));
const Products = lazy(() => import("./pages/Products/Products"));
const Admin = lazy(() => import("./pages/Admin/Admin"));
const AddProduct = lazy(() => import("./components/Admin/AddProduct"));
const ProductList = lazy(() => import("./components/Admin/ProductList"));
const EditProduct = lazy(() => import("./components/Admin/EditProduct"));
const AllOrders = lazy(() => import("./components/Admin/AllOrders"));
const AllBookings = lazy(() => import("./components/Admin/AllBookings"))
const DatesBooked = lazy(() => import("./components/Admin/DatesBooked"))
const AdminLogin = lazy(() => import("./pages/Admin/AdminLogin"))
const AllContactForms = lazy(() => import("./components/Admin/AllContactForms"))
const CuisineMain = lazy(() => import("./pages/WellnessCuisine/CuisineMain") )

const ProductDetails = lazy(() => import("./pages/ProductDetails/ProductDetails"));
const AllProducts = lazy(() => import("./components/products/AllProducts"));



function App() {
  return (
    <BrowserRouter>
    <FloatIcon/>
      <Suspense fallback={<div style={{ textAlign: "center" }}>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/review" element={<NewReviewPage />} />
          <Route path="/Terms-and-conditions" element={<><Navbar /><TermConditions/><Footer/></>} />
          <Route path="/cookies-policy" element={<><Navbar /><CookiesPolicy/><Footer/></>} />
          <Route path="/legal-note" element={<><Navbar /><LegalNote/><Footer/></>} />
          <Route path="/privacy-statement" element={<><Navbar /><PrivacyStatement/><Footer/></>} />
          <Route path="/dummy" element={<NewGallery/>}/>
             

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/wellness-package" element={<WellnessPackage />} />
          <Route path="/wellness-cuisine" element={<CuisineMain/>}/>
          <Route path="/the-centre" element={<TheCenter />} />

          <Route path="/products" element={<Products />}>
            <Route path="" element={<AllProducts />} />
            <Route path=":id" element={<ProductDetails />} />
        </Route>


        <Route path="admin-login" element={<AdminLogin/>}/>
          <Route path="/admin" element={<Admin />}>
            <Route path="addProduct" element={<AddProduct />} />
            <Route path="productList" element={<ProductList />} />
            <Route path="edit/:id" element={<EditProduct />} />
            <Route path="orders" element={<AllOrders />} />
            <Route path="all-bookings" element={<AllBookings/>}/>
            <Route path="dates-booked" element={<DatesBooked/>}/>
            <Route path="contact-forms" element={<AllContactForms/>}/>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
