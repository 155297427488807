import React,{useEffect} from "react";
import './PrivacyStatement.css'

function PrivacyStatement() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container privacy mt-5 mb-5">
      <h4 className="mb-4">
        <b className="bold">INTRODUCTION</b>
      </h4>
      Your privacy is important to us, so through this document, we explain what
      data we collect from users, how we use it and for what, among other
      indications. Those over 14 years of age may register as users without the
      prior consent of their parents, guardians or legal representatives. In the
      case of minors under 14 years of age or identified as "legally incapable",
      we will always need the consent of the parent, or of the guardian or legal
      representative.
      <br />
      <br />
      For your information, we will never ask for more information than we
      actually need for the required services; We will never share it with third
      parties, except by legal obligation or with your prior authorization; and
      we will never use your data for another purpose not previously indicated.
      <br />
      <br />
      As users, you should carefully read this Privacy Policy on a regular
      basis, and whenever you enter your personal data, since this document may
      be modified since the lender can modify any type of information that may
      appear on the web to comply with the regulations or update the policy, and
      without there being an obligation to pre-notify or inform users of said
      obligations, the publication on the provider's website being sufficient.
      <br />
      <br />
      <p>
        <h4>RESPONSIBLE FOR THE TREATMENT:</h4>
      </p>
      <ul>
        <li>
          <span className="bold">Identitat: </span>GLOBAL EMOTIMES, S.L. (also the provider OR MavaYoga)
        </li>
        <br />
        <li>
          <b className="bold">NIF:</b>B55312755
        </li>
        <br />
        <li>
          <b className="bold">Postal address:</b>C / Carme 46-48 2-3, Girona - SPAIN-
        </li>
        <br />
        <li>
          <b className="bold">Email:</b> mykhattars@gmail.com
        </li>
        <br />
        <li>
          GLOBAL EMOTIMES, S.L. o MavaYoga, as responsible for the website, in
          accordance with the provisions of Regulation (EU) 2016/679 of April
          27, 2016 (RGPD) regarding the protection of natural persons with
          regard to the processing of personal data and the free circulation of
          these data and other current regulations regarding the protection of
          personal data, and by Law 34/2002, of July 11, on Services of the
          Information Society and Electronic Commerce (LSSICE), informs you that
          has implemented the necessary security measures, of a technical and
          organizational nature, to guarantee and protect the confidentiality,
          integrity and availability of the data entered.
        </li>
      </ul>

      <p> <h4 className="bold">DATA OBTAINING METHOD AND DATA WE COLLECT:</h4> The data we collect and how we obtain it is as follows:</p>

      <ul>
        <li>through the <b className="bold">contact form:</b> name, email, age, telephone,</li>
        <br />
        <li>through the <b className="bold">registration form:</b> name, email, date of birth, gender,</li>
        <br />
        <li>using the newsletter <b className="bold">subscription form:</b> name, email,</li>
        <br />
        <li>by <b className="bold">subscribing to the forum</b> and / or comments on it: name, email,</li>
        <br />
        <li>through the <b className="bold">data collection form</b> for ecommerce: name, address, email, date of birth, gender, telephone number, bank details or information on a credit or debit card</li>
      </ul>

      <p>Specially protected data categories are not treated.</p>

      <p><b className="bold">PURPOSE OF THE TREATMENT:</b></p>

      <p>Your personal data will only be used for the following purposes, giving your consent for each of the purposes in the corresponding section of the website:</p>




      <ul>
        <li><b className="bold">contact form:</b> to respond to inquiries and / or provide information required for the User;</li>
        <br />
        <li><b className="bold">registration form:</b> for the creation of the user account, formalize a reservation request or a reservation directly and other functionalities of the web;</li>
        <br />
        <li>newsletter <b className="bold">subscription form:</b>: to send commercial advertising communications by email, SMS, social communities or any other electronic or physical means, if the User has expressly consented to it;</li>
        <br />
        <li><b className="bold">subscribing to the forum</b> and / or comments in it: when the user has registered in the forum, they will be able to comment within the forum, both to raise questions and to answer questions;</li>
        <br />
        <li><b className="bold">If you make a reservation</b>, to formalize it.</li>
      </ul>
      <br />

      <p>The photographs that are posted on the web are the property of MavaYoga, or of their legitimate owners, always having been obtained in a lawful manner and in accordance with current applicable regulations.</p>

      <p>You can revoke your consent at any time by sending a letter with the subject "Unsubscribe" to mykhattars@gmail.com.</p>

      <p>In accordance with the LSSICE, MavaYoga does not perform SPAM practices, therefore, it does not send commercial emails that have not been previously requested or authorized by the User. Consequently, in all the communications that the provider will receive, the user has the possibility of canceling their express consent to receive our communications.</p>

      <p>We will not process your personal data for any other purpose than those described except by legal obligation or judicial requirement.</p>

      <p>It will not be the subject of decisions based on automated treatments that produce effects on your data.</p>

      <p><b className="bold">LEGITIMATION OF THE TREATMENT</b></p>


      <p>The legal basis for the treatment of the data is your consent granted at the time of giving your data in the corresponding form to carry out the purposes, at the time of marking the checkbox.</p>
      <p>Failure to provide the requested personal data or not accepting this privacy policy implies the impossibility of achieving the purposes described above.</p>
      <p>In cases where there is a prior contractual relationship between the parties, the legitimacy for the development of the administrative, fiscal and accounting obligations that are necessary under current legislation will be the prior existence of the commercial relationship established between the parties.</p>
      <p>The prospective or commercial offer of services is based on the consent that is requested, without in any case the withdrawal of this consent conditioning the execution of the subscription contract.
      </p>
      <p><b className="bold">DATA CONSERVATION PERIOD:</b></p>
      <p>Your data will be kept for the duration of the commercial relationship with us or you exercise your right of cancellation or opposition, or limitation of treatment. However, we will keep your data within the legally established deadlines in the applicable provisions.</p>



      <p><b className="bold">RECIPIENTS OF ASSIGNMENTS OR TRANSFERS:</b></p>
      <p>
        MavaYoga communicates to the organizing entities of the events the necessary data to be able to formalize the experience. With the exception of this transfer, the data will not be communicated or transferred to any other entity that is not related to any of the withdrawals of the experiences shown on the provider's website.</p>
      <p>Likewise, MavaYoga  if required to do so by formal writing, will provide information to the security forces under judicial order or due to the obligation of a legal norm, without prejudice to being able to block or cancel your account if there may be indications of the commission. of any crime by the user. The information provided will only be that available to the provider at that time.</p>
      <p>
        Likewise, the provider uses certain tools to provide services necessary for the development of the activity, being able to store his data. These tools are as follows:
      </p>
      <p><b className="bold">Google Analytics:</b> to perform an analysis and metrics of the use that users make of the web through cookies. The information generated and collected by these cookies on the use (including the IP address) will be directly transmitted and filed by Google Inc., located in Delaware, 1600 Amphitheater Parkway, Mount View (California), CA 94043 in the United States ("Google" ), on its servers in the United States.</p>
      <p><b className="bold">
        Email Marketing: </b>it is used for the mail marketing service with which the treatment manager contract is duly signed;</p>
      <p><b className="bold">Google Adsense:</b> this website is associated with Google to show third-party ads to users who access this website. When collaborating with google, cookies are used to show ads related to your recent searches and to show more relevant ads.</p>
      <p>You can use the ad settings to manage the Google ads that are shown to you and indicate not to show ads based on your interests. Even if you decide not to receive these types of ads, they may continue to appear based on factors such as general location derived from IP address, browser type, and recent and previous research related to your current search.</p>
      <p><b className="bold">RIGHTS OF INTERESTED PERSONS:</b></p>
      <p>As a user-interested, you can request the exercise of the following rights before GLOBAL EMOTIMES, S.L. presenting a letter to the postal address of the heading or sending an email to mykhattars@gmail.com, indicating as Subject: "DATA PROTECTION: RIGHTS OF THE AFFECTED", and attaching a photocopy of your ID or any similar means in law, such as the law indicates.</p>
      <p><b className="bold">Rights:</b></p>



      <ul>
        <li><p><b className="bold">Right of access:</b> allows the interested party to know and obtain information about their personal data subjected to treatment.</p></li>
        <li><p><b className="bold">Right of rectification or deletion:</b>allows you to correct errors and modify the data that turn out to be inaccurate or incomplete</p></li>
        <li><p><b className="bold">Right of cancellation:</b> allows data that is inappropriate or excessive to be deleted</p></li>
        <li><p><b className="bold">Right of opposition:</b> right of the interested party not to carry out the processing of their personal data or to stop it.</p></li>
        <li><p><b className="bold">Limitation of the treatment:</b> it entails the marking of the personal data kept, in order to limit its future treatment, for the exercise or defense of claims.</p></li>
        <li><p><b className="bold">Portability of the data:</b> facilitation of the data object of treatment to the interested party, so that he can transmit to another person in charge, without impediments.</p></li>

        <li><p><b className="bold">Right not to be subject to automated individual decisions (including profiling):</b> right not to be subject to a decision based on automated processing that produces effects or significantly affects</p></li>

      </ul>




      As a user, you have the right to withdraw the consent given at any time. The withdrawal of consent will not affect the legality of the treatment carried out before the withdrawal of consent.
      <br />
      <br />
      You also have the right to file a claim with the supervisory authority if you consider that your rights may have been violated in relation to the protection of your data (aepd.es).

      <br />
      <br />

      <h4 className="bold">ADDITIONAL INFORMATION</h4>
      <br />
      <b className="bold">SECURITY MEASURES:</b>
      <br />
      <br />

      The data you provide us will be treated confidentially. The provider has adopted all the technical and organizational measures and all the necessary levels of protection to guarantee the security in the treatment of the data and avoid its alteration, loss, theft, treatment or unauthorized access, according to the state of technology and nature of the stored data. Likewise, it is also guaranteed that the treatment and registration in files, programs, systems or equipment, premises and centers comply with the requirements and conditions of integrity and security established in current regulations.

      <br />
      <br />

      <h4 className="bold">IDJOM</h4>
      <br />


      The language applicable to this privacy policy is Spanish. Therefore, if there is any contradiction in any of the versions provided in other languages, the Spanish version will prevail.
      <br />
      <br />

      <h4 className="bold">PROFILE ANALYSIS</h4>
      <br />

      The information provided in the different forms, during the interaction with the web and any other data provided during browsing, including the information provided by cookies in relation to browsing habits, implies acceptance of the processing of these data, in order to be segmented or categorized in order to apply them to the activity related to the management of general administration, information, commercialization of advertising spaces, elaborate profiles, analysis of proposals requested by users, statistical study of the services and contents used, tastes and preferences, etc. The data used in the elaboration of these profiles is not data that allows to identify the users but only shows in a graph the type of public that interacts with the web, age, country, gender, etc.
      <br />
      <br />
      The user who accesses this website, explicitly consents to the use of this generic information for profiling, segmentation and categorization of data for the purposes described in the previous section.
      <br />
      <br />

      <h4 className="bold">SOCIAL NETWORKS</h4>
      <br />

      We inform you that ETR has a presence on social networks: FACEBOOK and INSTAGRAM. The treatment of the data that is carried out of the people who become followers in the social networks (and carry out any link or connection action through the social networks) of the official pages of the provider will be governed by this section, as well as for those conditions of use, privacy policies and access regulations that belong to the social network that is appropriate in each case and previously accepted by the user.
      <br />
      <br />
      MavaYoga  will process your data for the purpose of correctly managing your presence on the social network, reporting activities, products or services of the provider, as well as for any other purpose that the regulations of Social Networks allow.
      <br />
      <br />
      The publication of content is prohibited:
      <br />
      <br />
      That they are allegedly illegal by national, community or international regulations or that they carry out allegedly illegal activities or contravene the principles of good faith.
      <br />
      <br />
      That violate the fundamental rights of people, lack of courtesy in the network, annoy or may generate negative opinions in our users or third parties and in general whatever the content that MavaYoga considers inappropriate.
      <br />
      <br />

      And in general that contravene the principles of legality, honesty, responsibility, protection of human dignity, protection of minors, protection of public order, protection of private life, consumer protection, and intellectual and industrial property rights.
      <br />
      <br />
      Likewise, MavaYoga reserves the right to withdraw, without prior notice from the website or the corporate social network, those contents that are considered inappropriate.
      <br />
      <br />
      In any case, if you send personal information through the social network, MavaYoga will be exempt from responsibility in relation to the security measures applicable to this platform and the user, if they want to know them, should consult the corresponding particular conditions of the network in question.

      <br />
      <br />

      <h4 className="bold">SUBSCRIPTION TO THE BLOG</h4>

      <br />

      In the event that the user subscribes to the Blog, we inform him that the data provided will be processed to manage his subscription to the informative Blog with an update notice and that they will be kept as long as there is a mutual interest to maintain the end of the treatment. When it is no longer necessary for this purpose, it will be deleted with adequate security measures to guarantee the pseudonymisation of the data or its total destruction. The data will not be communicated to third parties, except legal obligation.

      <br />
      <br />
      <h4 className="bold"> PARTICIPATION IN FORUMS</h4>
      <br />


      In the event that the user wants to participate in the web forums, we inform him that the data provided will be processed to regulate participation in the forum. Any registration or transmission of personal data that the user of this forum facilitates implies knowledge and unequivocal acceptance of the Legal Notice and the Privacy Policy displayed on the website. The data will be kept as long as there is a mutual interest to maintain the end of the treatment and when it is no longer necessary for that purpose, it will be deleted with adequate security measures to guarantee the pseudonimització of the data (where appropriate) or total destruction of the same. . The posts will be publicly displayed to users of the online forum.
      <br />
      <br />
      MavaYoga  is not responsible for any content posted on the forum entered by any registered user. The published content only expresses the point of view of its author. The author of the comment agrees not to use this blog to publish or link to content that is defamatory, abusive, hateful, threatening, offensive, abusive, spam or similar, adult content or that violates copyright or the law and public order usually. If the uploaded comments, in the opinion of the moderators, understand that they may harm and / or violate the rights of third parties or the law, they may be deleted without explanation.
      <br />
      <br />

      <h4 className="bold">PUBLICATION OF YOUR TESTIMONY</h4>
      <br />


      In case the user wants to publish his opinion on the web, we inform him that the data provided will be processed to meet the proposed suggestions, experiences or opinions regarding the products and / or services to be published on the web page and thus be able to help to other users. The data will be kept as long as there is a mutual interest to maintain the end of the treatment and when it is no longer necessary for that purpose, it will be deleted with adequate security measures to guarantee the pseudonimització of the data or their total destruction. Witnesses will be posted on our website. The only personal data that will be published about the witness will be her name.

      <br />
      <br />
      <h4 className="bold">FACEBOOK ADS</h4>
      <br />

      The provider's website uses Facebook ads, which is Facebook's advertising platform. This allows you to create campaigns and advertisements. When generating an ad, you can segment your audience by:
      <ul>
        <br />
        <li>Place</li>
        <br />
        <li>Demographic data (age, gender, etc.)</li>
        <br />
        <li>
          Interests (activities, hobbies, etc.)</li>
        <br />
        <li>What they buy on the internet and through other channels.</li>
        <br />
        <li>Etc.</li>
      </ul>

      <br />


      From the moment the user leaves their data in the form enabled to join the subscription newsletter, the data obtained through Facebook Ads are subject to this privacy policy. In no case will the information from Facebook be used for a different purpose.

      <br />
      <br />
      <h4 className="bold">GOOGLE ADWORDS</h4>
      <br />

      Adwords is a Google platform for ADVERTISERS who want to advertise on GOOGLE. The provider uses Google Adwords, which is Google's advertising platform. This allows you to create campaigns and advertisements to show them on other web pages. When generating an ad, you can segment your audience by:
      <br />
      <ul>
        <br />
        <li>Place</li>
        <br />
        <li>Demographic data (age, gender, etc.)</li>
        <br />
        <li>
          Interests (activities, hobbies, etc.)</li>
        <br />
        <li>What they buy on the internet and through other channels.</li>
        <br />
        <li>Etc.</li>
      </ul>
      <br />
      From the moment the user leaves their data in the form enabled to join the subscription newsletter, the data obtained through Google Adwords are subject to this privacy policy. In no case will the information from Google be used for a different purpose.
      <br />
      <br />
      The provider may select and publish customer testimonials to the Services. These witnesses may contain personally identifiable information, such as your name and / or city, state, or country in which you live. If you wish to have your name or testimonial removed from the website, you must contact the lender with the contact details above.
      <br />
      <br />

      <h4 className="bold">CHANGES TO THIS PRIVACY POLICY</h4>
      <br />

      MavaYoga  reserves the right to modify this policy to adapt it to new legislation or jurisprudence.
      <br />
      <br />

      <h4 className="bold"> REVOCABILITY</h4>
      <br />

      The consent given, both for the treatment and for the transfer of the data of the interested parties, is revocable at any time by communicating it to the provider in the terms established in this Policy for the exercise of rights. This revocation will in no case be retroactive.
      <br />
      <br />

      <h4 className="bold">LEGISLATION</h4>
      <br />
      In general, the relations between GLOBAL EMOTIMES, S.L. With the Users of its telematic services, present on this website, they are subject to Spanish legislation and jurisdiction to which the parties expressly submit, being competent for the resolution of all conflicts derived or related to their use the Courts and Tribunals of Girona.





    </div>
  );
}

export default PrivacyStatement;
