import React ,{useEffect}from "react";
import '../PrivacyStatement/PrivacyStatement.css'

function TermConditions() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="container privacy mt-5 mb-5">
      <h4 className="bold">1.PARTIES AND ACCEPTANCE OF THE GENERAL CONTRACTUAL TERMS AND CONDITIONS:</h4>
      <br />

      (hereinafter also the General Conditions or GCC):
      <br />
      <br />
      On the one hand, the supplier of the products, GLOBAL EMOTIMES, SL - MavaYoga (hereinafter also the provider, MavaYoga), with registered office at C / Carme nº 46-48 2-3 ,, 17004 de Girona -Spain-, NIF B55312755, customer service phone +34 660 10 10 16/656 18 50 12, and e-mail: mykhattars@gmail.com, being the owner of the website www.MavaYoga.com, exposes the contractual document that will govern the contracting of retreats and / or experiences published through the reviewed website. MavaYoga is the owner of this web platform where the retreats and experiences that it manages as an intermediary with the experience providers are shown. Experiences are the different retreats that Experience Providers offer (hereinafter experiences).
      <br />
      <br />
      On the other hand, the Experience Providers (hereinafter the Providers) are the companies that advertise, advertise, sell, promote and / or offer the experiences offered through the MavaYoga website and with whom it is finally contracted through the website. of MavaYoga, this being the means to reserve the experience selected by the user. The relationship between MavaYoga and Experience Providers is governed by different terms and conditions that make up the relationship between the two. Each Supplier acts professionally with GLOBAL EMOTIMES, SL by offering their experience through the MavaYoga website. Suppliers may request acceptance of, in addition to the terms and conditions of contracting this website, their own terms and conditions and rules of use, which may include forms of cancellation, disclaimers and limited liability.
      <br />
      <br />
      And on the other hand, the User - Client (hereinafter the User), registered on the website by means of a username and password, over which they have full responsibility for use and custody, being responsible for the veracity of the personal data provided. to the provider.
      <br />
      <br />


      ALL OF THEM Accept this document, and it implies that the user:
      <ul>
        <li>You've read, you understand and you're agree with this text.</li>
        <li>That he is a person of legal age and with sufficient capacity to contract.</li>
        <li>That he assumes all the obligations set forth herein.</li>
        <li>That you have read and accept these general contracting conditions for the experience and / or reservation from the moment you make the reservation or contract directly.</li>
      </ul>


      <br />

      This document can be printed and stored by Users.
      <br />
      <br />
      The provider makes the e-mail address mykhattars@gmail.com available to them so that they can raise any questions about these conditions.
      <br />
      <br />
      These conditions will have an indefinite period of validity and will be applicable to all contracts made through the provider's website.
      <br />
      <br />
      The provider reserves the right to unilaterally modify these General Conditions, without this affecting the experiences acquired prior to the modification, in order to improve them. In any case, before making the reservation or hiring an experience, these general conditions must be consulted.
      <br />
      <br />
      It is advisable that you keep a copy of the data contained in the reservations and experiences or retreats purchased.
      <br />
      <br />
      MavaYoga is not responsible for the consequences that may result from an inappropriate practice of the experiences exposed on the web.
      <br />
      <br />
      GLOBAL EMOTIMES, SL is a mere intermediary between the User and the providers of experiences offered through the MavaYoga website. The user renounces to claim any responsibility to MavaYoga for any concept in any case of dissatisfaction of the retreats acquired on the web www.MavaYoga.com, as well as possible failures, slow access or errors in access to the web, including losses of data or other information that may exist on the computer or network of the user who accesses the web.

      <br />
      <br />


      <h4 className="bold">2. OBJECT OF THE CONTRACT</h4>
      <br />

      <b className="bold">2.1 Scope of application and nature of the service:</b> The purpose of this contract is to regulate the contractual relationship of reservation and contracting of experiences, experiences or retreats born between the provider, the experience provider and the user at the moment in which he accepts these conditions purchase during the online contracting process by checking the corresponding box. These GTCs will apply from the day the order is placed.
      <br />
      <br />
      Through the Web Platform, MavaYoga provides an online platform through which Experience Providers advertise, advertise, sell, promote and / or offer their experiences to be requested, bought and booked, and through which users who visit the Platform can discover, search, compare, request, reserve, buy or pay. When making a Reservation or contracting of experience, a direct contractual relationship (legally binding) is established with the Experience Provider with whom you have booked. From the moment you make your Experience Reservation, MavaYoga acts solely as an intermediary between you and the Experience Provider, transmitting the relevant data of the reservation to the Experience Provider (s) and sending them a confirmation email on behalf of the Experience Provider. MavaYoga does not sell or offer any type of experiences directly.
      <br />
      <br />
      When providing our experience booking and booking service, the information provided is based on that provided by the Providers. They are fully responsible for updating the rates, charges, prices, availability, terms and conditions, and other relevant data that appear on our website. Although MavaYoga intends that the Service offering the experience is as accurate as possible, it cannot be verified or guaranteed that all the information is accurate, complete or correct. Nor are we responsible for errors (such as manifest and typographical errors), interruptions (due to temporary and / or partial server outages or repairs, updates and maintenance of our Platform or other reasons), inaccurate, misleading or false information, or lack of information. Each Provider is responsible at all times for the precision, accuracy and correctness of the information (both descriptive and referring to rates, charges, prices, terms and conditions and availability) that appears on our website. The MavaYoga website is not any, by way of example but not limitation, recommendation or promotion of the quality of the experiences, their services, facilities, venues, unless otherwise explicitly stated or established.
      <br />
      <br />
      The experience booking and booking service is available only for personal and non-commercial use. Therefore, the content, information and reservations made cannot be resold, used, copied, displayed, downloaded or reproduced.
      <br />
      <br />
      <b className="bold">2.2 Territory of application:</b> The virtual store of www.MavaYoga.com is active for everyone.
      <br />
      <br />
      <b className="bold">2.3 Capacity to contract:</b> In order to make a reservation or contract for experience, you must be of legal age and have the capacity to contract.
      <br />
      <br />
      <b className="bold">2.4 Customer acceptance:</b> The validation of a reservation or contract through the website www.MavaYoga.com is carried out by email and also implies the automatic acceptance of the GTC. These conditions are available on the website www.MavaYoga.com or, if you wish and request, we can make them available to you by email.
      <br />
      <br />
      <b className="bold">2.5. Modification of the General Contracting Conditions:</b> GLOBAL EMOTIMES, SL (MavaYoga) reserves the right to make changes and / or modifications in these GCC. We advise our clients to review them regularly. In the event that these changes or modifications are introduced once an order has been placed, the conditions in force on the date on which said order was placed will apply.
      <br />
      <br />
      <h4 className="bold">3.INFORMATION PROVIDED ON THE WEB  www.MavaYoga.com:</h4>
      <br />

      <b className="bold">3.1 Publication of prices:</b> Exceptionally, the prices of the experiences shown on our website may be wrongly specified and show a lower price than the corresponding one. When this happens, and if we have confirmed your reservation, we will immediately contact you in order to issue a new reservation confirmation with the correct price. In the event that the corresponding price is higher, you may cancel your reservation and we will inform you how to carry out the refund procedure that you have already satisfied.
      <br />
      <br />
      The prices are those indicated by the Experience Providers on our website. All are shown with sales taxes included and any other taxes (subject to changes in said taxes), unless otherwise indicated on our website or in the confirmation email. Fees and taxes (including tourism / municipal taxes) may be charged by the Experience Provider in case of no-show or cancellation.
      <br />
      <br />
      Sometimes a specific offer is indicated on the web. The data and conditions of the reservation should be consulted in detail to know the conditions before making the reservation.
      <br />
      <br />
      Obvious errors (including misprints) are not binding.
      <br />
      <br />
      <b className="bold">3.2 Product information:</b> The contents of www.MavaYoga.com are constantly renewed and updated to offer our clients the most complete and detailed information possible.
      <br />
      <br />
      All contractual information on the website is displayed in Spanish and english and communication with customers and users, as well as the formalization of the reservation or hiring of the experience, will be made in this language, although, if so The client wishes, it can be done in Catalan or another language, always indicating it before starting the contracting procedure.
      <br />
      <br />
      <b className="bold">3.3 Reservations 24 hours a day, every day of the year:</b> The reservation hours are twenty-four (24) hours three hundred and sixty-five (365) days of the year, and they will be processed as soon as possible. Reservations received during the weekend will be processed first thing in the morning on the following Monday, or the first business day.
      <br />
      <br />
      <b className="bold">3.4 Fraud:</b> If GLOBAL EMOTIMES, SL (MavaYoga) suspects or detects any anomaly or fraud, it reserves the right to cancel the transaction for security reasons.
      <br />
      <br />

      <h4 className="bold">4. RESERVATION OR CONTRACTING PROCEDURE:</h4>
      <br />

      MavaYoga acts as an intermediary between Users and experience providers, as the Reservation Center.
      <br />
      <br />
      Depending on the experience, they can have 2 forms of Reservation procedure: Reservation Request or Immediate Reservation.
      <br />
      <br />
      In the case of a Reservation Request, by clicking on the "Reservation Request" button, you will be redirected to the Request form where you must fill in the fields marked as mandatory with the requested personal data, names and travel companions, as well as the dates of travel and the type of room you want. In addition, a guarantee deposit is made for the reservation that is requested. After receiving your request, we will respond as soon as possible, always within a maximum period of 48 hours, with Affirmative or Negative confirmation. If affirmative, the reservation is confirmed and if it is negative, the immediate return of the deposit is made.
      <br />
      <br />
      In the case of Immediate Reservation, by clicking on the "Immediate Reservation" button, you will be redirected to the Reservation form where you must fill in the fields marked as mandatory with the requested personal information, names and travel companions, as well as dates travel and the type of room you want. After you send us the reservation payment, you will receive an automatic acknowledgment of receipt.
      <br />
      <br />
      After receiving the payment and the reservation confirmation with all the information: the names of the travelers, the exact dates of the trip and the experience you want to book, we will send you an official reservation confirmation. From that moment the reservation is final. Once the contract has been made, the vouchers and / or documents applicable to the specific reservation will be sent by e-mail.
      <br />
      <br />
      These Conditions, the specific conditions of sale of the rate reserved by you and the voucher or reservation request, express the integrality of the obligations between the parties.
      <br />
      <br />
      No general or specific condition communicated by you may be integrated into these Conditions.
      <br />
      <br />
      In no case will the contract between you and the travel provider or MavaYoga be effective before the confirmation email is issued. After receiving the confirmation of your reservation, the reservation can only be canceled / modified according to the cancellation conditions mentioned in the reservation confirmation.
      <br />
      <br />
      Once you have accessed the Web, to proceed with the contracting of the Services, you must follow all the indications and instructions contained in the purchase procedure, completing for these purposes the Conditions and other forms established for each experience, which will entail the reading and acceptance of all applicable General and Specific Conditions, for which we will ask you if you confirm that you have read our booking terms and conditions. By checking the corresponding box you give us your agreement and acceptance.
      <br />
      <br />
      For individualized inquiries, please fill out and submit the Contact Us form. You will find this form in the "Contact" menu. We will respond to you as soon as possible, within a maximum period of 48 hours.
      <br />
      <br />
      The user must register through the website by creating a user account. For this, the user must freely and voluntarily provide the personal data that will be required, which will be treated in accordance with the provisions of current regulations on data protection, Regulation (EU) 2016/679 of 27 April 2016 (RGPD) regarding the protection of natural persons with regard to the processing of personal data and the free circulation of these data, Organic Law 03/2018 of December 05 (LOPD) regarding the protection of Personal data and guarantee of digital rights, detailed in the Privacy Policy of this website.
      <br />
      <br />
      The user will have a username (which will be the email with which they have registered on the web platform) and a password, committing themselves to make diligent use of them, and not to make them available to third parties, as well as to communicate to the provider the loss or theft of the same or the possible access by an unauthorized third party, in such a way that it proceeds to the immediate blocking.
      <br />
      <br />
      In any case, the MavaYoga contracting platform will inform the user, once the contracting procedure is completed, via email regarding all the characteristics of the contracted or reserved experience.
      <br />
      <br />
      If there is any type of error in the indicated address or in any other point of the reservation, you must notify it immediately to the e-mail address that will appear on the web to proceed to correct this error.
      <br />
      <br />
      If you have any questions, you can contact our Customer Service through any of the methods provided on the website www.MavaYoga.com.
      <br />
      <br />
      GLOBAL EMOTIMES, SL (MavaYoga) will provide FREE Customer Service through our contact email mykhattars@gmail.com, if you choose another alternative means of communication, the user is the one who will have to bear the particular cost of the same.
      <br />
      <br />
      GLOBAL EMOTIMES, SL (MavaYoga) offers telephones in Spain subject to the cost of your telephone operator.
      <br />
      <br />
      You agree to confirm the veracity and accuracy of the information provided, considering void any contract in which you have provided false information. In cases where you have made a reservation with false information, you will be responsible for the processing and operating expenses generated by your reservation.
      <br />
      <br />

      <h4 className="bold">5. GIFT VOUCHER POLICY</h4>
      <br />

      The purchase of a gift voucher is another reservation formula on our website. The client buys a gift voucher that is sent to his email and that has a credit value to spend on any product offered by the web. This credit has an expiration date that is established up to 1 year after the purchase of the Voucher. All checks have a unique code that identifies them and is used to redeem for a product.
      <br />
      <br />
      With a valid gift voucher you can reserve any retreat or product published on our website.
      <br />
      <br />
      The reservation is made through MavaYoga for the value of the amount purchased. If it is lower, the difference will be added.
      <br />
      <br />
      The amount of 1 Voucher can be divided into 2 retreats if desired.
      <br />
      <br />
      It is not possible to return gift vouchers once the purchase has been made.
      <br />
      <br />
      You cannot use more than 1 voucher for the purchase or reservation of a service.
      <br />
      <br />
      <h4 className="bold">6. PAYMENT:</h4>
      <br />

      Depending on the reservation and / or experience, MavaYoga will inform you about the payment conditions at the time of booking.
      <br />
      <br />
      All retreats or experiences will require a payment on account at the time of booking. The amounts of the deposit can vary between a fixed amount up to 100% of the total amount of the reservation. This can be paid by credit card payment, always directly to MavaYoga. Depending on the retreat, the remaining amount (once the deposit has been paid), must be paid between the fourth week before arrival, and the day of the start of the experience. The means determined by the Provider of the experience will be used. In case of not making the deposit on the date indicated, the reservation will be automatically canceled.
      <br />
      <br />
      Prices will be confirmed in the currency chosen by the Experience Provider, including taxes and will only be valid during the period indicated on the website related to the experience, and once the reservation request has been made.
      <br />
      <br />


      <h4 className="bold">7. PAYMENT FORMS</h4>
      <br />

      Below we detail the available systems:
      <br />

      <b className="bold">7.1 Payment by credit or debit card:</b> The user must have a valid credit or debit card, where they must specify all the digits of the card number in the corresponding form. The operation is formalized at the time of placing the order. We only accept payments with secure cards in which your identity as the cardholder is authenticated according to the method established by your bank, through secure electronic commerce ("Verified by Visa" and "MasterCard SecureCode"). The order cannot be taken into account and will not be processed until the payment has been authorized by your bank or box.
      <br />
      <br />
      The system called SSL (Secure Sockets Layer) is used, a payment system in a secure environment since it allows the encryption of the information transmitted during the transaction, ensuring its confidentiality.
      <br />
      <br />
      <b className="bold">7.2 Payment by Paypal account: </b>The user must have an identifying Paypal account. The operation is formalized at the time of placing the order. The order cannot be taken into account and will not be processed until the payment has been authorized by Paypal.
      <br />
      <br />
      <b className="bold">7.3 Payment in installments through Scalapay:</b> If you buy with Scalapay, you will receive your order and pay in 3 installments. You confirm that the installments will be made and purchased by Retail Installations SPV 1 Limited, the related parties and their beneficiaries and you hereby unconditionally and irrevocably authorize and consent to this sale.
      <br />
      <br />
      <h4 className="bold">8. CANCELLATION</h4>
      <br />

      CANCELLATION BY THE USER:

      <br />
      <br />

      Upon receiving the reservation confirmation, MavaYoga will always inform you about the cancellation conditions. These conditions may vary depending on the retreat or retreat experience booked and the Experience Provider with whom you have contracted.
      <br />
      <br />
      When making a Reservation of a retreat experience, the User accepts the conditions of cancellation and non-presentation to the same of the Experience Provider, as well as other additional terms and conditions of said Provider that may affect their Trip (indicated in the sections of particular conditions of the experience provided by the provider of the retreat and made available on our website.
      <br />
      <br />
      The general cancellation and no-show conditions of each Experience Provider are available on our website, both on the Experience Provider's information pages and during the booking process and in the confirmation email. In certain cases, and when it is a specific offer, they may not accept cancellations. In case of no-shows or cancellations with charges, the Experience Provider may apply the payment of a certain amount and / or the tourist / municipal tax. You should consult in each specific case the particular conditions of each retreat experience before making the reservation.
      <br />
      <br />
      A Travel Reservation that requires the payment of a deposit or advance payment (total or partial) can be canceled (without prior notice) at the moment in which (the rest of) the amounts pending payment cannot be charged in full on the relevant date, in accordance with the Experience Provider's payment conditions and reservation conditions. The cancellation and prepayment conditions may vary according to each trip. Read carefully the cancellation section (at the bottom right of the MavaYoga website) and the additional information in your reservation confirmation to check the additional conditions that the Experience Provider may apply.
      <br />
      <br />
      Payment delays, incorrect bank details, credit or debit card details, invalid credit or debit cards or with insufficient balance are your responsibility and you may not benefit from the return or refund of the prepayment. (non-refundable) if any of these cases occurs, unless the Experience Provider accepts or allows it in its cancellation and payment conditions (in advance).
      <br />
      <br />
      If you want to review, modify or cancel your Travel Reservation, check the confirmation email and follow the instructions indicated. You must take into account that no direct refunds of the amount paid are made, but a refund is offered in the form of a credit with a certain validity (normally 1 year). Cancellation supplements may be applied in accordance with the conditions of cancellation, payment (in advance) and no-show upon retreat of the Experience Provider. You should always read the specific conditions before making the reservation and make subsequent payments on time as they may be necessary for the reservation.

      If you are going to be late or late on the day of check-in or if you arrive the next day, be sure to notify the Experience Provider, to know your arrival and avoid possible cancellations or no-show charges. MavaYoga is not responsible for the consequences that your delay may have or for any cancellation or no-show charge that the Experience Provider may make.
      <br />
      <br />
      In case you or one of your travel companions wants to cancel the reservation, MavaYoga will always need a written cancellation confirmation via email from the person who made the reservation with MavaYoga. Cancellations take effect from the moment MavaYoga receives the email.
      <br />
      <br />
      The following cancellation fees will apply. These conditions will apply in the event that the Provider has not specified or informed about other cancellation conditions determined and applied in your reservation:
      <br />
      <br />
      Up to 10 days in advance counting until the arrival date: 100% refund in the form of a credit valid for 1 year
      <br />
      <br />
      Less than 10 days counting until the arrival date: 100% of the total amount of the deposit, that is, no credit will be made.
      <br />
      <br />
      In case of no-show or unexpected departure: no refund of the deposit will be made.
      <br />
      <br />
      Once your stay has begun and in the case of unforeseen departure before the end of the contracted stay, there will be no refund, partial refund or refund of the days not enjoyed during your stay. If the reason for cancellation is covered by the conditions of cancellation insurance, you may be able to claim cancellation costs from your insurance company.
      <br />
      <br />
      In the event that the provider cancels the stay for reasons not attributable to you, we will contact you to offer you a new offer or a direct refund of the amount paid by you in relation to the canceled stay. In this case, you will not be able to claim any compensation.
      <br />
      <br />
      All modifications on request and the additional costs generated by the modifications will be borne by the client. Any modification of flight reservations will be your responsibility and will have to be made with the company with which you have contracted said service.
      <br />
      <br />
      <h4 className="bold">9. CANCELLATIONS OR MODIFICATIONS BY THE EXPERIENCE PROVIDER:</h4>
      <br />

      In the event that the provider of the retreat has to make modifications or cancellations, due to the fact that the experience is not available for any reason, MavaYoga reserves the right to communicate it through the web and we will inform you by e-mail if it had contracted or reserved said experience. We will inform you in the shortest possible time. In these cases, MavaYoga will refund 100% of the deposited amount made with the reservation of the experience. MavaYoga has no responsibility for the cancellation or modification of the experience made by the provider himself. The user must bear in mind that the contracted experiences may be subject to modification and / or cancellation by the provider and accept these conditions at the time of contracting, without you being able to claim reimbursement or compensation beyond the deposit to MavaYoga.
      <br />
      <br />
      Some external factors such as time or physical limitations can influence the execution of the contracted experience. The person responsible for it will always have the right to make the necessary adjustments without you being able to claim reimbursement or compensation.
      <br />
      <br />
      <h4 className="bold">10. CANCELLATION OR TRAVEL INSURANCE:</h4>
      <br />
      MavaYoga does not offer travel or cancellation insurance. If you book an experience that includes physical activity, it is highly recommended to have personal accident insurance. You would need your own travel insurance.
      <br />
      <br />

      <h4 className="bold">11. RESPONSIBILITY OF THE PROVIDER OF THE EXPERIENCE and ETR:</h4>
      <br />
      <br />

      MavaYoga is not responsible for any action, procedure, loss, claim, expenses, damages or responsibilities that are derived or are a consequence of the provision of services by the provider of the experience.
      <br />
      <br />
      Any complaint or claim against MavaYoga or regarding the reserved experience must be made immediately, or at most, within a period of 30 days after the scheduled date of the same (the date of departure). Any complaint or claim submitted outside the 30-day period may be rejected and the complainant will lose all rights to any type of compensation (for damage or cost).
      <br />
      <br />
      Notice for consumers in the European Economic Area: it is recommended that you first inform us of any claim through Customer Service. If that does not solve the problem, you can file the complaint through the European Union's online dispute resolution platform, which you will find here: http://ec.europa.eu/odr.
      <br />
      <br />
      <h4 className="bold">12. DISCLAIMER OF LIABILITY:</h4>
      <br />

      In accordance with the limitations indicated in these terms and conditions, and to the extent permitted by law, MavaYoga is only liable for direct damages that the user may suffer due to defects attributable to our obligations in reference to our services, up to a total amount of the cost of your retreat Reservation (the deposit), as indicated in the confirmation email.
      <br />
      <br />
      To the extent permitted by law, MavaYoga will not be liable for: (i) errors related to the description of information (including information on prices, availability and ratings) of the Experience Provider on our Platform; (ii) services provided or products offered by the Experience Provider or other business partners; (iii) losses, damages or costs (direct or indirect) that you suffer arising as a result of the use, unavailability or delay of our Platform; or (iv) any type of injury (personal), death, property damage or other damages, losses and expenses (direct or indirect) that the user may suffer as a result of acts, errors, infractions, negligence, professional misconduct, omissions, breaches, objective or non-contractual liability attributable to the Experience Provider whose retreat or experiences are available, offered or promoted on or through our website, including any (partial) cancellation, overbooking, strike, force majeure or other act that escapes our control.
      <br />
      <br />
      MavaYoga is not responsible (and disclaims any responsibility) for the use, validity, quality, relevance, adequacy and disclosure of the Trip and makes no representations, warranties or conditions of any kind in this regard. The user accepts that the Experience Provider is solely responsible and assumes all responsibility and obligation regarding the retreat or contracted experience (including any guarantees and representations made by the Experience Provider). MavaYoga is not the (re) seller of the retreat / experience. Complaints and claims regarding the same (including everything related to the price offered (which may be special or a promotion), conditions or specific requests made by Clients) must be managed with the Experience Provider. MavaYoga is not responsible and rejects all responsibility with respect to said complaints, claims and obligations regarding the retreat or experience contracted.
      <br />
      <br />
      Whether the Experience Provider has charged for the experience or retreat, or MavaYoga facilitates the payment of the price, the user accepts, at all times, that the Experience Provider is responsible for the collection, retention, shipment and payment of the applicable taxes on the total amount of the price. MavaYoga is not responsible for the shipping, collection, retention or payment of taxes on the price as MavaYoga is not the official seller of the retreat or experience.
      <br />
      <br />
      By uploading photos or other images to our system (for example, by submitting a comment) the user certifies, guarantees and confirms that they are in possession of the rights to the photograph / image / video and that they agree to MavaYoga using said photo or image on its website, apps, social networks and promotional materials (online and offline), as well as in any publication it deems appropriate. You are guaranteeing MavaYoga a non-exclusive, universal, irrevocable, unconditional and permanent right, as well as the license to use, reproduce, display, have reproduced, distribute, sublicense, communicate and facilitate the photos / images in the way that MavaYoga deems appropriate. . By uploading these photos / images, the person who uploads them accepts the legal and moral responsibility for each and every one of the legal claims made by a third party as a result of the use and publication of the photos / images by MavaYoga for the aforementioned purpose. . MavaYoga does not own or endorse in any way the photos / images that are uploaded. The veracity, validity and right of use of all photos / images will be assumed by the person who has uploaded the photo, and will never be the responsibility of the provider. The person who has uploaded the photos guarantees that the photos / images do not contain any viruses, Trojans or infected files, as well as any pornographic, illegal, obscene, offensive, objectionable or inappropriate material, and that they do not infringe the intellectual property rights, of copyright or privacy of third parties. Any photo / image that does not meet the aforementioned requirements will not be published and / or may be removed by MavaYoga at any time and without prior notice.
      <br />
      <br />
      <h4 className="bold">13. NULLITY AND INEFFECTIVENESS OF THE CLAUSES:</h4>
      <br />

      If any clause included in these General Conditions is declared, totally or partially, null or ineffective, such nullity or ineffectiveness will affect only said provision or the part of it that is null or ineffective, the General Conditions subsisting in everything else, having such provision, or the part of it that is affected, by not being placed.

      <br />
      <br />
      <h4 className="bold">14. APPLICABLE LAW AND JURISDICTION</h4>
      <br />

      These conditions will be governed or interpreted in accordance with Spanish legislation in that which is not expressly established. The provider and the user agree to submit any controversy that may arise when reserving or contracting the experience that is the object of these Conditions, to the Courts and Tribunals of the user's domicile.
      <br />
      <br />
      In the event that the user is domiciled outside of Spain, the provider and the user expressly waive any other forum, submitting to the Courts and Tribunals of the city of Girona (Spain).
    </div>
  );
}

export default TermConditions;
