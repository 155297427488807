import React,{useEffect} from "react";

function CookiesPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container privacy py-5">
      In accordance with the Spanish regulations that regulate the use of
      cookies in relation to the provision of electronic communications
      services, contained in Royal Decree Law 13/2012 of March 30, we inform you
      about the cookies used on the website of GLOBAL EMOTIMES, S.L. (also the
      provider) and the reason for its use. Likewise, it informs you that by
      browsing the Website you are giving your consent to use them.
      <br />
      <br />
      <p>
        <span>
          <b className="bold">WHAT ARE COOKIES?</b>
        </span>
      </p>
      A cookie is a file that is downloaded to your computer when you access
      certain web pages. Cookies allow a web page, among other things, to store
      and retrieve information about the browsing habits of a user or their
      equipment and, depending on the information they contain and the way they
      use their equipment, they can be used to recognize the user. Cookies are
      only associated with an anonymous user and their computer or device and do
      not provide references that allow their personal data to be known.
      <br />
      <br />
      <p>
        <span>
          <b className="bold">COOKIES AFFECTED BY THE REGULATIONS AND EXEMPT COOKIES</b>
        </span>
      </p>
      According to the EU directive, the cookies that require the informed
      consent of the user are analytical cookies and those for advertising and
      affiliation, excepting those of a technical nature and those necessary for
      the operation of the website or the provision of services. expressly
      demanded by the user.
      <br />
      <br />
      <p>
        <span>
          <b className="bold">TYPE OF COOKIES</b>
        </span>
      </p>
      a) Depending on the entity that manages them, there are own Cookies (those
      that are sent to the user's terminal equipment from a computer or domain
      managed by the editor itself and from which the service requested by the
      user is provided) and third parties (the that are sent to the user's
      terminal equipment from a computer or domain that is not managed by the
      publisher, but by another entity that processes the data obtained through
      cookies).
      <br />
      <br />
      b) Depending on the time they remain active, there are the session ones
      (designed to request and store data while the user accesses a web page)
      and the persistent ones (in which the data is stored in the terminal and
      can be accessed and processed during a period defined by the person
      responsible for the cookie - it can range from a few minutes to several
      years).
      <br />
      <br />
      c) Depending on the purpose for which they treat the information they
      collect, they may be:
      <br />
      <br />
      <p>
        <b className="bold">Technical cookies</b>{" "}
        <span>
          (necessary for the use of the website and the provision of the
          contracted service),
        </span>
      </p>
      <p>
        <b className="bold">Personalization cookies </b>
        <span>
          (which allow the user to access the service with predefined
          characteristics, such as language, browser type, regional
          configuration, etc.)
        </span>
      </p>
      <p>
        <b className="bold">Analysis cookies </b>
        <span>(they collect information on the use made of the web),</span>
      </p>
      <p>
        <b className="bold">Advertising cookies</b>
        <span>
          {" "}
          (they collect information about the preferences and personal choices
          of users),
        </span>
      </p>
      <p>
        <b className="bold">Affiliate cookies</b>
        <span>
          {" "}
          (they allow you to track visits from other websites, with which the
          website establishes an affiliation contract).
        </span>
      </p>
      <p>
        <b className="bold">TYPE OF COOKIES USED FOR THIS WEBSITE</b>
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Name</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Purpose</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Expiration</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Supplier</strong>
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p> etr_currency</p>
            </td>
            <td>
              <p>
                Cookie that stores the user's preference regarding the currency
                used
              </p>
            </td>
            <td>
              <p> 1 year</p>
            </td>
            <td>
              <p>MavaYoga</p>
            </td>
          </tr>

          <tr>
            <td>
              <p> etr_favorites</p>
            </td>{" "}
            <td>
              <p>
                {" "}
                This Cookie is used for when a user is not registered on the web
                to be able to save the list of her favorite products.
              </p>
            </td>{" "}
            <td>
              <p> 1 year</p>
            </td>{" "}
            <td>
              <p> MavaYoga</p>
            </td>
          </tr>

          {/* 3 */}
          <tr>
            <td>
              <p>laravel_session</p>
            </td>

            <td>
              <p>
                {" "}
                Own cookie to manage the session of each user. 1 year MavaYoga
                location Cookies used to find out which country you are in when
                a service is requested. This cookie is completely anonymous, and
                is only used to help guide content to your location.
              </p>
            </td>

            <td>
              <p> 1 year</p>
            </td>

            <td>
              <p> MavaYoga</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>location</p>
            </td>
            <td>
              <p>
                Cookies used to find out which country you are in when a service is requested. This cookie is completely anonymous, and is only used to help guide content to your location.
              </p>
            </td>
            <td>
              <p> 1 year</p>
            </td>
            <td>
              <p>MavaYoga</p>
            </td>
          </tr>
          <tr>
            <td>
              <p> visited_retreats</p>
            </td>
            <td>
              <p>
                This Cookie is used to show the products of interest to the user.
              </p>
            </td>
            <td>
              <p> 1 year</p>
            </td>
            <td>
              <p>MavaYoga</p>
            </td>
          </tr>

          <tr>
            <td>
              <p> visited_venues</p>
            </td>
            <td>
              <p>
                This Cookie is used to show the products of interest to the user.
              </p>
            </td>
            <td>
              <p> 1 year</p>
            </td>
            <td>
              <p>MavaYoga</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>_ga</p>
            </td>
            <td>
              <p>
                It is used to control unique visitors to the web, it stores a unique customer identifier.
              </p>
            </td>
            <td>
              <p> 2 years</p>
            </td>
            <td>
              <p>Google Analytics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_gid</p>
            </td>
            <td>
              <p>
                It is used to distinguish users.
              </p>
            </td>
            <td>
              <p> 1 day</p>
            </td>
            <td>
              <p>Google Analytics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_gat_gtag_UA_77542129_1</p>
            </td>
            <td>
              <p>
                It is used to distinguish users.
              </p>
            </td>
            <td>
              <p> 1 day</p>
            </td>
            <td>
              <p>Google Analytics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>G_ENABLED_IDPS</p>
            </td>
            <td>
              <p>

                It is used for the analysis of users on the web.
              </p>
            </td>
            <td>
              <p> 1 day</p>
            </td>
            <td>
              <p>Google Analytics</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_fbp</p>
            </td>
            <td>
              <p>

                It is used for web statistics and performance of advertising campaigns.
              </p>
            </td>
            <td>
              <p> 1 day</p>
            </td>
            <td>
              <p>Facebook</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>XSRF-TOKEN</p>
            </td>
            <td>
              <p>


                Used to prevent CSRF attacks
              </p>
            </td>
            <td>
              <p> 1 day</p>
            </td>
            <td>
              <p>
                MavaYoga</p>
            </td>
          </tr>

          <br />
          <br />

        </tbody>
      </table>
      While browsing our website, cookies from our social networks may be stored in your browser. Below, we indicate the links on the cookies of the social networks that we use on this website:
<br />
<br />
Facebook cookies, see more information in their cookie policy
<br />
<br />
Instagram cookies, see more information in its cookie policy
<br />
<br />
Likewise, the provider carries out remarketing actions with Google AdWords, Facebook ads, which uses cookies to offer online advertisements based on previous visits to this website. These cookies are about to expire and do not contain personally identifiable information. For more information go to the Google advertising Privacy notice. 
<br />
<br />
 

<h4 className="bold">REVOCATION</h4>
<br />

You can access your browser settings at any time by accepting or rejecting all cookies, or by selecting those that the installation supports and those that do not, by following one of the following procedures, which depends on the browser you use:
<br />
<br />
<h4 className="bold">Google Chrome</h4>(in the Tools Menu):

Settings> Show advanced options> Privacy (Content settings)> Cookies:
<br />
<br />

https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es
<br />
<br />

<h4 className="bold">Microsoft Internet Explorer </h4>(in the Tools Menu):

Internet Options> Privacy> Advanced:
<br />
<br />

https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
<br />
<br />

<h4 className="bold">Firefox:</h4>
<br />

Options> Privacy> Cookies:
<br />
<br />

https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencia
<br />
<br />

<h4 className="bold">Safari, iPad and iPhone:</h4>
<br />

Preferences> Privacy
<br />
<br />

https://support.apple.com/kb/ph21411?locale=es_ES
<br />
<br />

<h4 className="bold">Opera:</h4>
<br />

Settings> Options> Advanced> Cookies
<br />
<br />

http://help.opera.com/Windows/12.00/es-ES/cookies.html
<br />
<br />

These browsers are subject to changes or modifications, so we cannot guarantee that they fully conform to the version of your browser. It may also be that you use another browser not covered by these links such as Konqueror, Arora, Flock, etc. To avoid these mismatches, you can access it directly from your browser options, generally in the "Options" menu in the "Privacy" section. (Please consult your browser's help for more information).

<br />
<br />

<h4 className="bold">DEACTIVATION / ACTIVATION AND ELIMINATION OF COOKIES</h4>

To restrict or block cookies, it is done through the browser settings.
<br />
<br />
If you do not want websites to place any cookies on your computer, you can adapt your browser settings so that you are notified before any cookies are placed. In the same way, you can adapt the settings so that the browser rejects all cookies, or only third-party cookies. You can also delete any of the cookies that are already on your computer. Remember that the configuration of each browser and equipment you use must be adapted separately.
<br />
<br />
Remember that if you do not want to receive cookies, we can no longer guarantee that our website works as intended. Some site features may be lost and you may no longer be able to view certain websites. Also, declining cookies does not mean that you will no longer see advertisements. The ads simply will not be tailored to your interests and will be repeated more frequently.
<br />
<br />
Each browser has a different method to adapt the settings. If necessary, refer to the help function of the browser to configure the correct settings.
<br />
<br />
To disable cookies on your mobile phone, see the device manual for more information.
<br />
<br />
You can obtain more information about cookies on the Internet, http://www.aboutcookies.org/.
<br />
<br />
Taking into account the way the Internet and websites work, we do not always have information about the cookies placed by third parties through our website. This applies especially to cases where the website contains what are called embedded elements: texts, documents, images or short movies that are stored elsewhere, but are displayed on or through our website.
<br />
<br />
Therefore, in case you come across these types of cookies on this website and they are not listed in the previous list, we kindly ask you to let us know. Or, contact the third party directly to request information about the cookies it places, the purpose and duration of the cookie, and how it has guaranteed your privacy.
<br />
    </div>
  );
}

export default CookiesPolicy;
