import React from "react";
import './NewGallery.css'

import beach from "../../assets//images/home_page_carousel/beach.jpg"
import beach_horse from "../../assets//images/home_page_carousel/beach_horse.jpeg"
import beach_person from "../../assets//images/home_page_carousel/beach_person.jpeg"
import beach_road from "../../assets//images/home_page_carousel/beach_road.jpeg"
import beach_yoga from "../../assets//images/home_page_carousel/beach_yoga.jpeg"
import detox from  "../../assets//images/home_page_carousel/detox.jpg"
import detox1 from  "../../assets//images/home_page_carousel/detox1.jpg"
import drinks from "../../assets//images/home_page_carousel/drinks.jpg"


import food_item1 from "../../assets//images/home_page_carousel/food_item1.jpeg"
import green_drink2 from "../../assets//images/home_page_carousel/green_drink2.jpg"
import general_yoga_jardin from "../../assets//images/home_page_carousel/general_yoga_jardin.jpg"
import hill from "../../assets//images/home_page_carousel/hill.jpeg"
import gym from "../../assets//images/home_page_carousel/gym.jpg"
import hotel_spa from  "../../assets//images/home_page_carousel/hotel_spa.jpg"
import house from  "../../assets//images/home_page_carousel/house.jpg"
import massage from "../../assets//images/home_page_carousel/massage.jpg"


// import mudras_beach from "../../assets//images/home_page_carousel/mudras_beach.jpg"
// import new_img from "../../assets//images/home_page_carousel/new_img.png"
// import people from "../../assets//images/home_page_carousel/people.jpeg"
// import pool_view from "../../assets//images/home_page_carousel/pool_view.jpeg"
// import pool1 from "../../assets//images/home_page_carousel/pool1.jpg"
// import sea_view from "../../assets//images/home_page_carousel/sea_view.jpeg"
// import pool3 from  "../../assets//images/home_page_carousel/pool3.jpg"
// import purple_food from  "../../assets//images/home_page_carousel/purple_food.jpg"
// import relax_room1 from "../../assets//images/home_page_carousel/relax_room1.jpg"


// import pool_indoor from "../../assets//images/home_page_carousel/pool_indoor.jpg"
// import orange_drink from "../../assets//images/home_page_carousel/orange_drink.jpg"
// import yoga_garden from "../../assets//images/home_page_carousel/yoga_garden.jpg"
// import boat from "../../assets//images/home_page_carousel/boat.jpg"
// import food3 from "../../assets//images/home_page_carousel/food3.jpg"

// import green_food from "../../assets//images/home_page_carousel/green_food.jpg"
// import trees from "../../assets//images/home_page_carousel/trees.jpeg"
// import tea from "../../assets//images/home_page_carousel/tea.jpg"
// import hill1 from "../../assets//images/home_page_carousel/hill1.jpeg"
// import hill_and_beach from "../../assets//images/home_page_carousel/hill_and_beach.jpeg"

import collage from "../../assets//images/collage.png"

export default function NewGallery(){
    return(

        <div className="gallery-full-collage">
            <img src={collage}/>
        </div>
        // <div className="new-gallery-grid-container">
            
        //     {/*one*/}
        //     <div className="new-gallery-grid-item">
        //             <img src={beach}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={beach_person}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={beach_horse}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={beach_road}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={beach_yoga}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={detox}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={detox1}/>
        //     </div>

        //     {/*two*/}
        //     <div className="new-gallery-grid-item">
        //         <img src={drinks}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={food_item1}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={green_drink2}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={general_yoga_jardin}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={hill}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={gym}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={hotel_spa}/>
        //     </div>
            

        //       {/*three*/}
        //       <div className="new-gallery-grid-item">
        //         <img src={house}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={massage}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={mudras_beach}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={new_img}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={people}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={pool_view}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={pool1}/>
        //     </div>
            

            
        //       {/*four*/}
        //       <div className="new-gallery-grid-item">
        //         <img src={pool3}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={purple_food}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={relax_room1}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={sea_view}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //          <img src={pool_indoor}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={orange_drink}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={yoga_garden}/>
        //     </div>

     
        //     <div className="new-gallery-grid-item">
        //         <img src={boat}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={food3}/>   
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={green_food}/>  
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={trees}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={tea}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //             <img src={hill1}/>
        //     </div>
        //     <div className="new-gallery-grid-item">
        //         <img src={hill_and_beach}/>
        //     </div>


         

        // </div>
    )
}