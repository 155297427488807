import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

export default function NavDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, left: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "100% ", height: "100%", background: "#455010" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/wellness-package" className="navbarList__item-drawer">
                WELLNESS PACKAGES{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/the-centre" className="navbarList__item-drawer">
                THE CENTER{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/products" className="navbarList__item-drawer">
                STORE{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>
      

      <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/wellness-cuisine" className="navbarList__item-drawer">
                WELLNESSS CUISINE{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/our-story" className="navbarList__item-drawer">
                OUR STORY{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>
    {/* {user.email &&   <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/book" className="navbarList__item-drawer">
                BOOKING
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>} */}
       {/* { user.email && <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Link to="/myBooking" className="navbarList__item-drawer">
                MY BOOKING{" "}
              </Link>
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>} */}


        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
          
              {/* { user.email?<li onClick={logOut} className="navbarList__item-drawer">
                  <button  className="logout-btn">
                   Logout
                  </button>
                </li>: <li className="navbarList__item-drawer">
                  <Link to="/login" className="navbarList__item">
                   LOGIN
                  </Link>
                </li>}
           */}
            </ListItemIcon>
            <ListItemText primary="" />
          </ListItemButton>
        </ListItem>
      </List>
      

      

      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/* <Toolbar className="hamburger2">
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            // sx={{ mr: 2 }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar> */}
        <div className="hamburger2" onClick={toggleDrawer("left", true)}>
          <FontAwesomeIcon style={{fontSize:'35px'}} icon={faBars} color="white" />
        </div>

        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          sx={{ width: "90%" }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
