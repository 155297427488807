import review5 from "../../assets//images/new_review/review5.png"
import review4 from "../../assets//images/new_review/review4.png"

export default [
    {
        name: 'Maria Esther M. 42 years old. Seville (Spain)',
        date: '06/11/2022',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '10',
        comment: `It has been a wonderful experience, a gift for the senses and a cure for the soul.
        Mava and her team are fabulous and very special people, always so attentive and close that they make you feel at home. 
        The setting is beautiful, the activities varied and well organized and the food delicious. 
        Looking forward to coming back with all of you. Namaste.`
    },

    {
        name: 'Ignacio Lería R. 38 years old. Ibiza (Spain)',
        date: '11/06/2021',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '10',
        comment: `I cannot be more grateful for the experience lived.
        It was my first retirement but I'm sure it won't be the last. 
        Mava takes care of all the details and makes you feel like family. 
        From minute 1, you can feel the energy and good vibes of the house that does not lack a detail.
         My perfect and very spacious room, even for couples, the food is very balanced and rich in its entirety, the daily walks are spectacular and very enriching since we visited various places in the area and all very different from each other. In short, if you need to connect with yourself, what better way to do it than with her, Maria Vanesa! A hug.`,
       
    },
    {
        name: 'John B. 40 years old. Coslada (Spain)',
        date: '11/06/2021',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '9',
        comment: `Mava is a super friendly person, you can't imagine it, the house is spectacular, located in a luxurious urbanization, Lola is super friendly too. 
        The areas and towns that we visit are very beautiful, everything is well chosen, 
        the yoga that is practiced is basic, and many meditations are done with breathing that 
        provide tranquility.`,
        improve: `I would improve the diet, although of course, it is a detox retreat and I think it should be like that.`
    },
    {
        name: 'Laura E. 45 years old. Bilbao (Spain)',
        date: '08/08/2021',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '10',
        comment: `Totally recommended. A fantastic house to rest and take care of yourself. 
        Surrounded by an environment full of vegetation, a garden that inspires a lot of harmony, in which it is a pleasure to practice yoga. The delicious food. Mava is charming, she takes great 
        care of the details so that one feels at home. I will return without a doubt..`,
    },
    {
        name: 'Jorge V. 69 years old. Madrid (Spain)',
        date: '08/24/2022',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        rating: "10",
        comment: `I have done a three-week fast plus a reintroduction to eating an Ayurvedic detox 
        diet. The experience has exceeded all my expectations, both in terms of surroundings 
        and accommodation, as well as in the exquisite human attention, the delicious food and 
        the varied and interesting activities, including the pool, the theoretical contribution 
        to Ayurveda and yoga practices. I have to highlight the excellent yoga practice 
        that we received every morning, guided by the expert hand of the facilitator, Mava Arenas Khattar. It has been a discovery. 
        Also worth mentioning is the meeting with the other guests, who have all been excellent people, without exception. Hopefully I can repeat the experience every year. Absolutely recommended`,
        improve: `I can't think of how to improve the near perfection of this offering.`
    },
    
 
    {
        name: 'Anita D. 47 years old. Neyruz (Switzerland)',
        date: ' 01/28/2023',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '10',
        comment: `Mava's benevolence is very attentive. The apartment and only 5 minutes walk from the sea, the view is incredible.
         The hikes are varied and it's great to be able to get to know the surroundings that way. 
         Fabulous experience!!`
    },
    {   name: 'Angels B. 55 years old. Barcelona (Spain)',
        date: `  03/18/2022`,
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review5,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '10',
        comment: `Couldn't be better. It is a truly transformational retreat. 
        I encourage those who want growth in their lives to take advantage of this opportunity. Yoga classes are a 10!`
    },
    {
        name: 'Ana O. 59 years old. Castilblanco de los Arroyos (Spain) ',
        date: '12/09/2021',
        humanTeamStars: review5,
        activitiesStars: review5,
        priceQualityStars: review4,
        aroundStars: review5,
        accomodationStars: review5,
        feedingStars: review5,
        averageRating: '9.6',
        comment: `It has been an experience that has exceeded my expectations. 
        Mava is an excellent professional, affectionate and close with an exquisite style and education`,
        improve: 'Nothing'
    }
]