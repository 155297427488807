import React from "react";
import NewReview from "./NewReview";
import Navbar from "../Navbar";
import Footer from "../Footer";

export default function NewReviewPage(){
    return(
        <>
            <Navbar/>
                <NewReview/>
            <Footer/>
        </>
    )
}